import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: '',
    loadChildren: () => import('./menu-admin/menu-admin.module').then( m => m.MenuAdminPageModule)
  },
  
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'plantilla-indice-productos',
    loadChildren: () => import('./plantilla-indice-productos/plantilla-indice-productos.module').then( m => m.PlantillaIndiceProductosPageModule)
  },
  {
    path: 'plantilla-producto',
    loadChildren: () => import('./plantilla-producto/plantilla-producto.module').then( m => m.PlantillaProductoPageModule)
  },
  
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'catalogo',
    loadChildren: () => import('./catalogo/catalogo.module').then( m => m.CatalogoPageModule)
  },
  {
    path: 'zoom-image-modal',
    loadChildren: () => import('./zoom-image-modal/zoom-image-modal.module').then( m => m.ZoomImageModalPageModule)
  },
  {
    path: 'nuevo-producto',
    loadChildren: () => import('./nuevo-producto/nuevo-producto.module').then( m => m.NuevoProductoPageModule)
  },
  {
    path: 'lista-productos',
    loadChildren: () => import('./lista-productos/lista-productos.module').then( m => m.ListaProductosPageModule)
  },
  {
    path: 'nuevo-catalogo',
    loadChildren: () => import('./nuevo-catalogo/nuevo-catalogo.module').then( m => m.NuevoCatalogoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'menu-admin',
    loadChildren: () => import('./menu-admin/menu-admin.module').then( m => m.MenuAdminPageModule)
  },
  {
    path: 'lista-catalogos',
    loadChildren: () => import('./lista-catalogos/lista-catalogos.module').then( m => m.ListaCatalogosPageModule)
  },






];
//Para debug de router enableTracing: true 
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
